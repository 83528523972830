import { MobileSettingsKeys, SettingsKeys } from './SettingsKeys';

export const OneToManyOverridesStock = {
  [MobileSettingsKeys.MOBILE_CATEGORY_NAME_COLOR]:
    SettingsKeys.CATEGORY_NAME_COLOR,
  [MobileSettingsKeys.MOBILE_CATEGORY_NAME_FONT]:
    SettingsKeys.CATEGORY_NAME_FONT,
  [MobileSettingsKeys.MOBILE_CATEGORY_BACKGROUND_COLOR]:
    SettingsKeys.CATEGORY_BACKGROUND_COLOR,
};
