import * as React from 'react';
import { Dropdown } from 'wix-ui-tpa/Dropdown';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../../context/running-environment-context';
import s from './DropDownCategories.st.css';
import { CategoriesProps } from '../Categories';

interface DropDownCategoriesProps
  extends RunningEnvironmentProps,
    CategoriesProps {}

class DropDownCategoriesComponent extends React.PureComponent<
  DropDownCategoriesProps
> {
  constructor(props: DropDownCategoriesProps) {
    super(props);
    this.onCategorySelected = this.onCategorySelected.bind(this);
  }

  onCategorySelected(newSelectedCategoryIndex) {
    this.props.onCategorySelected(newSelectedCategoryIndex);
  }

  render() {
    const {
      categoriesViewModel,
      selectedCategoryIndex,
      // runningEnvironment,
    } = this.props;
    const { categories } = categoriesViewModel;
    const options = categories.map((category, index) => ({
      id: `${index}`,
      isSelectable: true,
      value: category.name,
    }));

    const dropDownProps = {
      initialSelectedId: `${selectedCategoryIndex}`,
      onChange: selectedCategory =>
        this.onCategorySelected(Number(selectedCategory.id)),
      options,
    };

    return <Dropdown {...dropDownProps} {...s('root', {}, dropDownProps)} />;
  }
}

export const DropDownCategories = withRunningEnvironmentContext(
  DropDownCategoriesComponent,
);
