import offeringListStyle from '../MultiOfferings.st.css';
import s from './OfferingList.st.css';
import { OfferingViewModelFactory } from '../../../domain/offering-view-model-factory';
import OfferingView from '../../OfferingView/OfferingView';
import { translate, TransProps } from 'react-i18next';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';
import * as React from 'react';
import { Grid } from 'wix-ui-tpa/Grid';
import { SIDE_PADDING } from '../constants';
import { OfferingListWidgetDisplayOptions } from '../../../display-options/offering-list-widget-display-options';
import { OfferingDomain } from '../../../domain/offering-domain';
import { IMultiOfferingsViewModel } from '../../../domain/multi-offerings-view-model-factory';
import {
  ExperimentsProps,
  withExperimentsContext,
} from '../../../../Shared/context/experiments-context';

export const OfferingListComponent = props => {
  const { t, displayOptions, offeringsDomain, runningEnvironment } = props;
  const { isMobile, isRTL } = runningEnvironment;
  let isVerticalAligned = true;
  const offeringViewModels = offeringsDomain.map(offeringDomain => {
    const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
      offeringDomain,
      displayOptions.offeringDisplayOptions,
      t,
      isRTL,
      isMobile,
    );
    isVerticalAligned =
      isVerticalAligned && offeringViewModel.verticalAlignInfoContent;
    return offeringViewModel;
  });

  return (
    <ul className={offeringListStyle.cardsContainer}>
      {offeringViewModels.map(offeringViewModel => {
        offeringViewModel.verticalAlignInfoContent = isVerticalAligned;
        return (
          <li
            className={offeringListStyle.cardContainer}
            key={`offering-${offeringViewModel.id}`}
          >
            <OfferingView offeringViewModel={offeringViewModel} />
          </li>
        );
      })}
    </ul>
  );
};

interface OfferingGridProps
  extends RunningEnvironmentProps,
    TransProps,
    ExperimentsProps {
  displayOptions: OfferingListWidgetDisplayOptions;
  offeringsDomain: OfferingDomain[];
  multiOfferingsViewModel: IMultiOfferingsViewModel;
}

export class OfferingGridComponent extends React.PureComponent<
  OfferingGridProps
> {
  static displayName = 'OfferingGrid';

  constructor(props) {
    super(props);
  }

  private get width(): number {
    const { width } = this.props.runningEnvironment.dimensions;

    return width ? width - SIDE_PADDING * 2 : 0;
  }

  private get items() {
    const {
      t,
      displayOptions,
      offeringsDomain,
      runningEnvironment,
    } = this.props;
    const { isMobile, isRTL } = runningEnvironment;

    let isVerticalAligned = true;
    const offeringViewModels = offeringsDomain.map(offeringDomain => {
      const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
        offeringDomain,
        displayOptions.offeringDisplayOptions,
        t,
        isRTL,
        isMobile,
      );
      isVerticalAligned =
        isVerticalAligned && offeringViewModel.verticalAlignInfoContent;

      return offeringViewModel;
    });

    return offeringViewModels.map(offeringViewModel => {
      offeringViewModel.verticalAlignInfoContent = isVerticalAligned;
      return (
        <Grid.Item key={`offering-${offeringViewModel.id}`}>
          <OfferingView offeringViewModel={offeringViewModel} />
        </Grid.Item>
      );
    });
  }

  render() {
    const { multiOfferingsViewModel, runningEnvironment } = this.props;

    const {
      spaceBetweenOfferings,
      maxItemsPerRow,
      dividerWidth,
      cardMinWidth,
      cardMaxWidth,
      withDivider,
      uniformRowHeight,
    } = multiOfferingsViewModel;

    return (
      <Grid
        {...s('root')}
        width={this.width}
        maxColumns={maxItemsPerRow}
        minColumnWidth={cardMinWidth * runningEnvironment.scale}
        maxColumnWidth={cardMaxWidth}
        dividerWidth={dividerWidth}
        showRowDivider={withDivider}
        rowGap={spaceBetweenOfferings}
        columnGap={spaceBetweenOfferings}
        uniformRowHeight={uniformRowHeight}
      >
        {this.items}
      </Grid>
    );
  }
}

class AbTest extends React.PureComponent<ExperimentsProps & OfferingGridProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { experiments } = this.props;

    if (experiments.isWidgetPhaseOnePointFiveEnabled) {
      return <OfferingGridComponent {...this.props} />;
    }

    return <OfferingListComponent {...this.props} />;
  }
}

export const OfferingList = withRunningEnvironmentContext(
  withExperimentsContext(translate(null, { wait: true })(AbTest)),
);
