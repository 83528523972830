import { isMobileFromFormFactor } from '../utils';
import {
  mergeMobileDefaultsIntoDesktop,
  mergeMobileSettingsIntoDesktop,
} from './mobileToDesktopKeys';
import { OneToManyOverridesStock } from './OneToManyOverridesStock';

const hexToRGBA = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const normalizeOpacity = opacity => (opacity === 0 ? 0 : opacity || 100);
const isFont = data => data && !!data.editorKey;
const isColor = data => data && !!data.themeName;

function overrideSiteFontIfNeeded(font, siteTextPresets, isSettingsPanel) {
  if (isFont(font)) {
    const siteFont = Object.values(siteTextPresets).find(
      (textPreset: any) => textPreset.editorKey === font.editorKey,
    ) as any;

    const overloadedFont = {
      ...siteFont,
      ...font,
      family: siteFont.fontFamily,
    };

    overloadedFont.style = {
      bold: overloadedFont.weight === 'bold',
      italic: overloadedFont.style === 'italic',
    };

    const stringToTrim = 'font:';

    if (
      overloadedFont.value &&
      overloadedFont.value.indexOf(stringToTrim) === 0
    ) {
      overloadedFont.value = '';
    }

    return isSettingsPanel ? overloadedFont : JSON.stringify(overloadedFont);
  }
  return font;
}

function overrideSiteColorIfNeeded(color, siteColors) {
  if (isColor(color)) {
    const siteColor = siteColors.find(
      ({ reference }) => reference === color.themeName,
    );

    const overloadedColor = {
      reference: color.themeName,
      value: hexToRGBA(siteColor.value, normalizeOpacity(color.opacity)),
    };

    return overloadedColor;
  }
  return color;
}

function noChangeWasMadeOnMobile(props) {
  return !props.settingsUserData[props.key];
}

export function overrideMobileDefaultData(props) {
  const { key, settingsUserData } = props;
  if (noChangeWasMadeOnMobile(props)) {
    return settingsUserData[OneToManyOverridesStock[key]];
  }
}

function userChangeDataOnlyOnDesktop(props) {
  return overrideMobileDefaultData(props);
}

export function overrideSiteDate(
  data,
  siteTextPresets,
  siteColors,
  isSettingsPanel,
) {
  if (isFont(data)) {
    return overrideSiteFontIfNeeded(data, siteTextPresets, isSettingsPanel);
  }

  if (isColor(data)) {
    return overrideSiteColorIfNeeded(data, siteColors);
  }

  return data;
}

export function overrideStyleProps(props) {
  const defaultOverrideData = {};
  const isMobile = isMobileFromFormFactor(props);
  const { siteTextPresets, siteColors } = props.host.style;
  let { settingsUserData, settingsDefaultData } = props;
  if (isMobile) {
    settingsDefaultData = mergeMobileDefaultsIntoDesktop(settingsDefaultData);
    settingsUserData = mergeMobileSettingsIntoDesktop(settingsUserData);
  }

  // here we prepere the data with the designer/ux overrides
  for (const key in settingsDefaultData) {
    const defaultData = settingsDefaultData[key];

    const overrideData =
      OneToManyOverridesStock[key] &&
      userChangeDataOnlyOnDesktop({ key, settingsUserData })
        ? overrideMobileDefaultData({
            key,
            settingsUserData,
          })
        : overrideSiteDate(defaultData, siteTextPresets, siteColors, false);
    defaultOverrideData[key] = overrideData;
  }

  const styleParamsData = {
    ...props.host.style.styleParams.colors,
    ...props.host.style.styleParams.fonts,
  };

  const settingsData = {
    ...settingsDefaultData, // ALL THE DATA FROM THE SITE FLAT!!
    ...defaultOverrideData, // DESINGER OVERRIDE
    ...styleParamsData, // Data from Style params (ADI preset recorder)
    ...settingsUserData, //USER DATA
  };

  const style = {
    ...props.host.style,
    styleParams: {
      colors: settingsData,
      booleans: settingsData,
      fonts: settingsData,
      numbers: settingsData,
    },
  };

  return {
    ...props,
    style,
    settingsData,
  };
}
